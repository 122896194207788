import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import LearnMore from './LearnMore';

import Header from './Header';
import Footer from './Footer';
import SideMenu from './SideMenu';

// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/app.scss';

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const Layout = ({ data, children, bodyClass }) => {
  const site = data.allGhostSettings.edges[0].node;
  return (
    <>
      <Helmet>
        <html lang={site.lang} />
        <link rel="stylesheet" href="//fonts.googleapis.com/css?family=Roboto:100,300,300italic,regular,italic,500,500italic,700,700italic" />
        <style type="text/css">{`${site.codeinjection_styles}`}</style>
        <body className={bodyClass} />
      </Helmet>

      <SideMenu>
        <Header />
      </SideMenu>
      <main className="site-main">
        {/* All the main content gets inserted here, index.js, post.js */}
        {children}

        <LearnMore />
      </main>

      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  bodyClass: PropTypes.string,
  data: PropTypes.shape({
    allGhostSettings: PropTypes.object.isRequired
  }).isRequired
};

const LayoutSettingsQuery = props => (
  <StaticQuery
    query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
            }
        `}
    render={data => <Layout data={data} {...props} />}
  />
);

export default LayoutSettingsQuery;
