import { useStaticQuery, graphql } from 'gatsby';

// TODO:
// add weighted category filtering
export function useTopCategories () {
  const { tags } = useStaticQuery(graphql`
    query RankedTags {
      tags: allGhostTag(limit: 4, sort: { fields: postCount, order: DESC }) {
        edges {
          node {
            ghostId
            name
            slug
            postCount
          }
        }
      }
    }
  `);

  return tags.edges.map(({ node }) => {
    return {
      ...node,
      url: `/categories/${node.slug}`
    };
  });
}
