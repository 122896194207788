import React from 'react';
import { UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Link } from 'gatsby';
import { useTopCategories } from 'hooks/categories';
import Hamburger from './Hamburger';
import { useSideMenu } from './SideMenu';
import 'styles/header.scss';

const Header = () => {
  const topCategories = useTopCategories();
  const { toggle } = useSideMenu();

  return (
    <header className="fixed-top">
      <nav className="navbar h-100">
        <div className="container d-flex h-100 flex-align-center">
          <Link className="navbar-brand d-flex align-items-center" to="/">
            <img src="https://storage.googleapis.com/futurefamily-blog/future-family.svg" alt="Futurefamily" />

            <div className="vertical-line" />
            <div className="blog-text mr-auto">blog</div>
          </Link>
          <div className="d-none d-md-flex h-100 align-items-center">
            <UncontrolledDropdown inNavbar className="ml-auto mr-3" tag="div">
              <DropdownToggle nav caret>
                Categories
              </DropdownToggle>
              <DropdownMenu>
                {topCategories.map(category =>
                  <DropdownItem
                    key={category.ghostId}
                    tag={Link}
                    to={category.url}
                    className="nav-link"
                  >
                    {category.name}
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          <div className="d-flex d-md-none">
            <Hamburger onClick={toggle} />
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
