import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import cx from 'classnames';
import 'styles/post-card.scss';

const PostCard = ({ post, ...props }) => {
  const url = `/${post.slug}/`;

  return (
    <article {...props} className={cx('post-card', props.className)}>
      <Link to={url}>
        <div className="card h-100">
          <div
            className="card-img-top" style={{
              backgroundImage: `linear-gradient(0deg, transparent, transparent), url('${post.feature_image}')`
            }}
          />
          <div className="card-body text-left">
            <div className="d-flex mb-3 align-items-center">
              {post.primary_tag &&
                <div className="category">
                  {post.primary_tag.name}
                </div>
              }
              <div className="date">{post.published_at_pretty}</div>
            </div>
            <h5 className="card-title title">{post.title}</h5>
            <div className="card-text description d-none d-md-flex">{post.excerpt}</div>
          </div>
        </div>
      </Link>
    </article>
  );
};

PostCard.propTypes = {
  post: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    feature_image: PropTypes.string,
    featured: PropTypes.bool,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string
      })
    ),
    primary_tag: PropTypes.shape({
      name: PropTypes.string
    }),
    published_at_pretty: PropTypes.string,
    excerpt: PropTypes.string.isRequired,
    primary_author: PropTypes.shape({
      name: PropTypes.string.isRequired,
      profile_image: PropTypes.string
    }).isRequired
  }).isRequired,
  className: PropTypes.string
};

export default PostCard;
