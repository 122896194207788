import React from 'react';
import 'styles/learn-more.scss';

export const LearnMore = () => (
  <section className="learn-more">
    <h2 className="mb-4 font-bold">Learn how Future <br className="d-flex d-md-none" />Family can help you</h2>
    <h4 className="mb-4 font-bold">Flexible financing plans and dedicated support for all your fertility needs</h4>
    <div className="text-center mt-3">
      <a href="https://www.futurefamily.com/">Find Out More</a>
    </div>
  </section>
);

export default LearnMore;
