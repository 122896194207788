import React from 'react';
import 'styles/footer.scss';

const copyYear = new Date().getFullYear();

const Info = () => (
  <>
    <div className="d-flex terms justify-content-center justify-content-md-start">
      <a href="https://www.futurefamily.com/privacy">Privacy</a>
      <a href="https://www.futurefamily.com/tou">Terms</a>
      <a href="/sitemap.xml">Sitemap</a>
    </div>

    <div className="rights-reserved justify-content-center justify-content-md-start mt-1 mt-md-0">
      &copy; {copyYear} Future Family. All rights reserved.
    </div>
  </>
);

const Footer = () => (
  <footer>
    <div className="container">
      <div className="d-flex flex-column flex-md-row justify-content-md-between">
        <div className="d-flex text-center justify-content-center">
          <a className="navbar-brand d-flex align-items-center" href="https://www.futurefamily.com/">
            <img className="logo" src="https://storage.googleapis.com/futurefamily-images/logo/ff-box.svg" alt="Future Family" />
          </a>

          <div className="d-none d-md-flex flex-column ff-info text-left justify-content-center">
            <Info />
          </div>
        </div>

        <div className="social-media d-flex align-items-center justify-content-center mt-4 mt-md-0">
          <a href="https://www.instagram.com/futurefamilyfertility/" rel="noopener noreferrer" target="_blank">
            <img src="https://storage.googleapis.com/futurefamily-images/social/instagram.png" alt="instagram" />
          </a>
          <a href="https://twitter.com/futurefamilysf" rel="noopener noreferrer" target="_blank">
            <img src="https://storage.googleapis.com/futurefamily-images/social/twitter.png" alt="twitter" />
          </a>
          <a href="https://www.linkedin.com/company/future-family/" rel="noopener noreferrer" target="_blank">
            <img src="https://storage.googleapis.com/futurefamily-images/social/linkedin.png" alt="linkedin" />
          </a>
          <a href="https://www.facebook.com/futurefamilyfertility/" rel="noopener noreferrer" target="_blank">
            <img src="https://storage.googleapis.com/futurefamily-images/social/facebook.png" alt="facebook" />
          </a>
        </div>

        <div className="d-flex d-md-none flex-column ff-info text-center mt-4 mt-md-0">
          <Info />
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
