import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import 'styles/hamburger.scss';

const Hamburger = ({ onClick, active }) => (
  <div className="hamburger d-flex">
    <button aria-label="Menu" aria-controls="navigation" onClick={onClick}>
      <span className="box">
        <span className={cx('inner', { active })} />
      </span>
    </button>
  </div>
);

Hamburger.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool
};

export default Hamburger;
