import React, { useContext, useCallback, useState, createContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useTopCategories } from 'hooks/categories';
import Hamburger from './Hamburger';
import cx from 'classnames';
import 'styles/side-menu.scss';

const MenuContext = createContext();

const SideMenu = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const topCategories = useTopCategories();

  return (
    <MenuContext.Provider value={{ isOpen, setIsOpen, toggle }}>
      {children}
      <div className="side-menu">
        <div className={cx('wrapper d-flex flex-column', {
          active: isOpen
        })}
        >
          <div className="d-flex justify-content-end x-margin">
            <Hamburger active={isOpen} onClick={toggle} />
          </div>
          <div>
            <nav className="navbar px-0">
              <div className="title x-margin">Categories</div>
              {topCategories.map(category =>
                <div key={category.ghostId} className="nav-item category">
                  <Link className="nav-link" to={category.url}>
                    {category.name}
                  </Link>
                </div>
              )}
            </nav>
            <hr className="x-margin" />
            <div className="ff-link">
              <a className="nav-link" rel="noopener noreferrer" target="_blank" href="https://www.futurefamily.com/">
                Learn more
              </a>
            </div>
          </div>
        </div>
      </div>
    </MenuContext.Provider>
  );
};

SideMenu.propTypes = {
  children: PropTypes.node
};

export default SideMenu;

export const useSideMenu = () => useContext(MenuContext);
